import React from 'react'
import { useStaticQuery, Link, graphql } from "gatsby"
import DarkToggle from '../ThemeContext/DarkToggle'
import styles from './ProjectsNavigation.module.css'
export default function ProjectsNavigation({children, projectID}) {
    const data = useStaticQuery(
        graphql`
          query {
            allMarkdownRemark(filter: {fields: {isRoot: {eq: true}}}, sort: {fields: frontmatter___projectID, order: ASC}) {
              edges {
                node {
                  id
                  fields {
                    isRoot
                    slug
                  }
                  frontmatter {
                    projectID
                  }
                }
              }
            }
          }
        `
      )
    return (
        <div>
                    <DarkToggle></DarkToggle>
        <div className = {styles.outerFlex}>
            <Link to = "/">
                <div className = {styles.circle}>
                    <img className = {styles.face} src = {require('../Hero/images/header.png')} alt = "Home Icon" ></img>
                </div>
            </Link>
            {data.allMarkdownRemark.edges.map((node) => {
                return <Link
                    key = {node.node.id}
                    to={node.node.fields.slug}>
                                  <div className = {styles.logo + " " + styles.card} >
                    <img className = {styles.logoImage} src = {require('../../images/icons/' + node.node.frontmatter.projectID +'.png')} alt = "socialDrop Icon" ></img>
                </div>
              </Link>
            }
            )}
        </div>
        {children}
        </div>
    )
}

