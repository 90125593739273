import React from 'react'
import ProjectsNavigation from '../components/ProjectsNavigation/ProjectsNavigation'
import SEO from '../components/SEO/seo'
import styles from '../components/404.module.css'
export default function ProjectDetail({data}) {
    return (
        <ProjectsNavigation>
            <SEO title = "Page Not Found"/>
            <div className = {styles.flex}>
                <div className = {styles.main}>
                    <h2>The Page You Are Looking For Could Not Be Found</h2>
                </div>
            </div>
        </ProjectsNavigation>
    )
}
